.drop_area{
    height:300px;
}

.guZdik{
    height: 30px !important;
}

.dmajdp > span {
    color:#CCC !important
}