.darkdateicon {
  color-scheme: dark;
}
.subscriptionStartDate > div {
  padding: 2px;
}
.subscriptionEndDate > div {
  padding: 2px;
}

.errorMessage-addclient {
  color: red !important;
  font-size: 12px;
}